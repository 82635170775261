<template>
    <v-container fluid>
        <v-row dense>
            <v-col md="4" lg="3" cols="12">
                <CommitteeSidebar :SessionId="SessionId"></CommitteeSidebar>
            </v-col>
            <v-col md="8" lg="9" cols="12">
                <v-card flat class="pr-4">
                    <v-card-title v-if="currentRoute.includes('/Interim')">{{Session.Year}} Interim Committees</v-card-title>
                    <v-card-title v-else-if="Session && Session.Year">{{Session.YearString}} Committees</v-card-title>
                    <v-card-text v-if="currentRoute.includes('/Interim')">
                        {{InterimPageText}}
                        <a rel="noopener" :href="ruleOfProcedureURL">Rules of Procedure</a>
                    </v-card-text>
                    <v-card-text v-else>
                        <div v-html="SessionPageText"></div>
                        <div v-if="false" class="pt-4">
                            <span style="color: red">Groups or individuals wanting to testify before the committee via remote means or submit a digital handout or written testimony may submit their request and/or materials to:</span>
                            <FilterableDataTable :headers="Headers" :items="SessionEmails" item-key="SessionCommitteeId" disable-pagination hide-default-footer class="elavation-1">
                                <template v-slot:item.Name="{item}">
                                    <div style="text-align:left" class="hidden-lg-and-up">
                                        <a rel="noopener" :href="'/Session/Committee/' + item.SessionCommitteeId + '/Detail'">{{item.Name}}</a>
                                    </div>
                                    <div class="hidden-md-and-down">
                                        <a rel="noopener" :href="'/Session/Committee/' + item.SessionCommitteeId + '/Detail'">{{item.Name}}</a>
                                    </div>
                                </template>
                                <template v-slot:item.Email="{item}">
                                    <div style="text-align:left" class="hidden-lg-and-up">
                                        <a rel="noopener" :href="'mailto:'+item.Email+'?body=Name:%0ACity%20of%20Residence:%0APhone%20Number:%0ARepresenting:%0ABill%20Number:%0AProponent%20or%20Opponent:%0AMeeting%20Date:%0A'">{{item.Email}}</a>
                                    </div>
                                    <div class="hidden-md-and-down">
                                        <a rel="noopener" :href="'mailto:'+item.Email+'?body=Name:%0ACity%20of%20Residence:%0APhone%20Number:%0ARepresenting:%0ABill%20Number:%0AProponent%20or%20Opponent:%0AMeeting%20Date:%0A'">{{item.Email}}</a>
                                    </div>
                                </template>
                            </FilterableDataTable>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    const CommitteeSidebar = () => import("@/Menus/CommitteeSidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    export default {
        name: 'Committees',
        props: ['SessionId'],
        components: {
            CommitteeSidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                fetch('/api/Sessions/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                    .then(res =>
                    {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201)
                        {
                            return res.json();
                        }
                        else
                        {
                            return null;
                        }
                        throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        this.Session = EncodeDatetimes(response);
                    })
                    .then(() =>
                    {
                        return fetch('/api/Documents/Menu?sessionId=' + this.Session.SessionId, { method: 'GET' });
                    })
                    .then(r =>
                    {
                        if (r.ok)
                            return r.json();
                        else
                            return r.text().then(r => Promise.reject(r));
                    })
                    .then(r =>
                    {
                        this.ruleOfProcedureURL = this.$MyLRCUrl.value + 'api/Documents/RulesOfProcedure/' + r.RuleOfProcedure.DocumentId + '.pdf'
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    });


            },
            LoadSessionText()
            {
                fetch('/api/SessionCommittees/' + this.SessionId + '/Emails', { method: 'GET' }).then(r =>
                {
                    if (r.ok)
                    {
                        r.json().then(r =>
                        {
                            this.SessionEmails = r;
                        });
                    }
                });
            },
        },
        data: function ()
        {
            return {
                Session: {},
                SessionPageText: 'The primary function of a standing committee is to consider thoroughly each bill or resolution assigned to the committee and to make a specific recommendation on what action should be taken regarding the bill or resolution. A standing committee is required to take final action on every legislative proposal assigned to the committee. <br /><br />There are thirteen standing committees in each house, divided by subject matter. In the Senate, most committees consist of seven or nine senators. The President Pro Tempore, with advice from the Majority Leader and Minority Leader, selects the members of each Senate committee and the chair and vice-chair. In the House, most committees consist of thirteen or fifteen representatives. The Speaker of the House, with advice from the Majority Leader and Minority Leader, selects the members of the House committees and the chair and vice-chair. Most legislators are members of two committees. Some serve on more than two committees, but that is rare due to the fact that two or three committees are usually meeting at the same time.<br /><br /> Following the introduction and first reading of a bill in either house, the presiding officer assigns the bill to a committee based on the subject matter. Generally, bills dealing with certain subjects are assigned to the same committee.',
                SessionEmails: [],
                InterimPageText: '',
                ruleOfProcedureURL: null,
                Headers: [{
                    text: 'Committee',
                    align: 'left',
                    sortable: true,
                    value: 'Name',
                    width: '50ch',
                    visible: true,
                    filterable: true,
                    Filter: null
                }, {
                    text: 'Email',
                    align: 'left',
                    sortable: true,
                    value: 'Email',
                    width: '*',
                    visible: true,
                    filterable: true,
                    Filter: null
                }]
            }
        },
        mounted: function ()
        {
            this.LoadSessionText();
            this.LoadData();
        },
        watch: {
            SessionId: function ()
            {
                this.LoadData();
            }
        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path;
            },
            Title: function ()
            {
                let title = 'Committees';
                if (this.Session && this.Session.Year)
                {
                    title = this.Session.Year + ' ' + title;
                }
                return title;
            },
            Description: function ()
            {
                return this.SessionPageText;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>