<template>
    <div class="d-print-none">
        <v-card class="hidden-sm-and-down">
            <v-progress-linear indeterminate
                               v-if="loadingMenu"></v-progress-linear>
            <v-tabs v-if="currentRoute.includes('/Session')" show-arrows class="hidden-sm-and-down">
                <v-tab v-for="i in tabs"
                       :key="i"
                       @click="filterCommittee(i)">
                    {{ i }}
                </v-tab>
            </v-tabs>
            <v-skeleton-loader class="mx-auto hidden-sm-and-down" v-if="loadingMenu && SubMenuItems"
                               max-width="256"
                               type="list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item"></v-skeleton-loader>


            <v-list dense class="hidden-sm-and-down">
                <v-list-item v-for="item in SubMenuItems"
                             :key="item.Name"
                             link
                             :to="'/' + CommitteeType +'/Committee/' + (item.InterimYearCommitteeId || item.SessionCommitteeId || item.ConferenceCommitteeId)">
                    <v-list-item-content>
                        <v-list-item-title v-if="currentRoute.includes('/Session')  && item.Committee != null" class="text-wrap">
                            <span v-if="!item.Committee.FullBody && item.Committee.Body != 'A'">{{item.Committee.BodyName}}</span> {{item.Committee.Name }}
                        </v-list-item-title>
                        <v-list-item-title v-else class="text-wrap">
                            {{item.Name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
        <div class="hidden-md-and-up">
            <div class="hidden-md-and-up justify-end">
                <v-btn @click.stop="drawer1 = !drawer1">
                    <v-icon>{{dotsvertical}}</v-icon>
                </v-btn>
            </div>
        </div>
        <v-navigation-drawer v-model="drawer1"
                             right
                             fixed
                             temporary>
            <v-list dense>
                <v-list-item v-for="item in SubMenuItems"
                             :key="item.Name"
                             link
                             :to="'/' + CommitteeType +'/Committee/' + (item.InterimYearCommitteeId || item.SessionCommitteeId || item.ConferenceCommitteeId)">
                    <v-list-item-content>
                        <v-list-item-title v-if="currentRoute.includes('/Session') && item.Committee != null" class="text-wrap">
                            <span v-if="!item.Committee.FullBody && item.Committee.Body != 'A'">{{item.Committee.BodyName}}</span> {{item.Committee.Name }}
                        </v-list-item-title>
                        <v-list-item-title v-else class="text-wrap">
                            {{item.Name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import { mdiDotsVertical } from '@mdi/js';
    export default {
        name: 'CommitteeSidebar',
        props: {
            SessionId: Number | String,
        },
        components: {
        },
        methods: {
            filterCommittee(item) {
                this.filterValue = item;
            },
            LoadData() {
                this.loadingMenu = true;
                if (this.SessionId) {
                    if (this.currentRoute.includes('/Interim')) {
                        fetch('/api/InterimCommittees/Session/' + this.SessionId, {
                            method: "GET",
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json"
                            },
                        }).then(res => {
                            this.loadingMenu = false;
                            if (res.status === 200 || res.status === 201) {
                                return res.json();
                            }
                            else {
                                return null;
                            }
                            throw new Error(res.statusText);
                        }).then(response => {
                            this.blankSubMenuItems = EncodeDatetimes(response);
                        }).catch(error => {
                            throw new Error(error);
                        });
                    } else {
                        fetch('/api/SessionCommittees/Session/' + this.SessionId, {
                            method: "GET",
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json"
                            },
                        }).then(res => {
                            this.loadingMenu = false;
                            if (res.status === 200 || res.status === 201) {
                                return res.json();
                            }
                            else {
                                return null;
                            }
                            throw new Error(res.statusText);
                        }).then(response => {
                            if (response) {
                                this.blankSubMenuItems = EncodeDatetimes(response);
                            }
                        }).catch(error => {
                            throw new Error(error);
                        });
                    }
                }
            },
        },
        data: function () {
            return {
                dotsvertical: mdiDotsVertical,
                filterValue: 'All',
                tabs: ['All', 'House', 'Senate', 'Joint'],
                loadingMenu: true,
                drawer1: false,
                blankSubMenuItems: [
                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            currentRoute: function () {
                this.LoadData();
            },
            SessionId: function () {
                this.LoadData();
            }
        },
        computed: {
            SubMenuItems: function () {
                if (this.currentRoute.includes('/Interim')) {
                    return this.blankSubMenuItems;
                }
                else {
                    if (this.filterValue == 'All') {
                        return this.blankSubMenuItems;
                    } else {
                        return this.blankSubMenuItems.filter(x => x.Committee && x.Committee.Body == this.filterValue.substring(0, 1));
                    }
                }
            },
            currentRoute: function () {
                return this.$route.path;
            },
            CommitteeType: function () {
                //switching between session and interim
                if (this.currentRoute.includes('/Interim')) {
                    return "Interim";
                }
                else {
                    return "Session";
                }
            }
        }
    };
</script>
<style></style>