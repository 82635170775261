<template>
    <v-card xs12 flat>
        <v-card-title>
            <h2>
                Legislative Memorial Service
            </h2>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-layout row wrap>
                    <v-flex xs12 sm12 v-for="i in Documents" :key="i.DocumentId">

                        {{ i.Year + '&nbsp;Memorial'}}
                        <v-btn text depressed x-small style="vertical-align:top;" rel="noopener" :href="createMYLRCLink(i.DocumentId)">
                            <v-icon color="gray">{{pdf}}</v-icon>
                        </v-btn>
                        <v-btn v-if="getVideo(i) != null && isAudio(i) == false" depressed x-small color="red" style="vertical-align:top;" rel="noopener" :href="getVideo(i)">
                            <v-icon color="white">{{play_arrow}}</v-icon>
                        </v-btn>

                        <v-btn v-if="getVideo(i) != null  && isAudio(i) == true" depressed x-small style="vertical-align:top;" aria-label="SDPB Audio" text rel="noopener" :href="getVideo(i)">
                            <v-icon>{{mdiVolumeHigh}}</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>

</template>
<script>
    import { mdiFilePdfBox } from '@mdi/js';
    import { mdiYoutube } from '@mdi/js';
    import { mdiVolumeHigh } from '@mdi/js';

    export default {
        name: 'Memorial',
        props: ['SessionId'],
        components: {

        },
        methods: {
            LoadData()
            {
                fetch('/api/Documents/DocumentType?Type=78', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201)
                    {
                        return res.json();
                    }
                    else
                    {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response =>
                {
                    this.Documents = EncodeDatetimes(response).sort((a, b) => (a.Year > b.Year) ? -1 : 1);

                }).catch(error =>
                {
                    throw new Error(error);
                });
            },
            createMYLRCLink(docid)
            {
                return this.$MyLRCUrl.value + 'api/Documents/' + docid + '.pdf';
            },
            getVideo(i)
            {
                if (this.Videos.find(x => x.year == i.Year))
                {
                    return this.Videos.find(x => x.year == i.Year).url;
                } else
                {
                    return null;
                }
            },
            isAudio(i)
            {
                if (this.Videos.find(x => x.year == i.Year))
                {
                    return this.Videos.find(x => x.year == i.Year).audioOnly;
                } else
                {
                    return null;
                }
            }

        },
        data: function ()
        {
            return {
                pdf: mdiFilePdfBox,
                play_arrow: mdiYoutube,
                mdiVolumeHigh,
                Documents: [

                ],
                Videos: [
                    {
                        url: 'https://www.youtube.com/live/G-USZhFuIy8?t=3840s',
                        year: '2025',
                        audioOnly: false,
                    },
                    {
                        url: 'https://www.youtube.com/live/mZZsF-iY9K0?si=9mSq1ZSenQ1MfDT5&t=4180',
                        year: '2024',
                        audioOnly: false,
                    },
                    {
                        url: 'https://www.youtube.com/live/s6qul1tELis?feature=share&t=3902',
                        year: '2023',
                        audioOnly: false,
                    },
                    {
                        url: 'https://sdpb.sd.gov/SDPBPodcast/2022/specials/MemorialService01202022.mp3',
                        year: '2022',
                        audioOnly: true,
                    },
                    {
                        url: 'https://youtu.be/a9sVvfK14cE',
                        year: '2021',
                        audioOnly: false,
                    },
                    {
                        url: 'https://www.youtube.com/watch?v=vI-SxMlAi3w',
                        year: '2020',
                        audioOnly: false,
                    },
                    {
                        url: 'https://youtu.be/Jx4J3cnv1rg?t=4468',
                        year: '2019',
                        audioOnly: false,
                    },
                    {
                        url: 'https://youtu.be/9CHzKEpsVTU?t=1h5m22s',
                        year: '2018',
                        audioOnly: false,
                    },
                    {
                        url: 'https://www.youtube.com/watch?v=-2gZdYWKpuQ',
                        year: '2017',
                        audioOnly: false,
                    },
                    {
                        url: 'https://www.youtube.com/watch?v=hCP4xY-ZWbc&feature=youtu.be&t=1h10m6s',
                        year: '2016',
                        audioOnly: false,
                    },
                    {
                        url: 'https://www.youtube.com/watch?v=rYy1tL8ZUYE',
                        year: '2015',
                        audioOnly: false,
                    },
                    {
                        url: 'https://www.youtube.com/watch?v=zOE_xqmg2eo',
                        year: '2014',
                        audioOnly: false,
                    },
                    {
                        url: 'https://www.youtube.com/watch?v=Np74PpaENAg',
                        year: '2013',
                        audioOnly: false,
                    },
                ],
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            SessionId: function ()
            {
                this.LoadData();
            }
        },
        computed: {
            Session: function ()
            {
                return this.$Sessions.value.find(x => x.SessionId == this.Bill.SessionId);
            },
            Title: function ()
            {
                let title = 'Legislative Memorial Service';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>